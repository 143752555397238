exports.fetchParams = (method, body) => {
  let params = {
    method: method,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Cache-Control': 'no-cache',
      'pragma': 'no-cache'
    },
    credentials: 'include',
  };
  if(typeof body !== "undefined") {
    params.body = JSON.stringify(body);
  }
  return params;
}
