// counterReducer.js

import * as actionType from '../actions/ActionType';

const admin = (state = 0, action) => {
  switch (action.type) {
    case actionType.LOGIN:
      if(typeof action.user.role !== "undefined") {
        if(action.user.role === "admin") {
          return true;
        } else {
          return false;
        }
      }
      /* falls through */

    case actionType.LOGOUT:
      return false;
      /* falls through */
    default:
      return state
  }
}

export default admin;
