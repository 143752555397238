// counterReducer.js

import * as actionType from '../actions/ActionType';

const user = (state = {}, action) => {
  switch (action.type) {
    case actionType.LOGIN:
      return action.user;
    case actionType.LOGOUT:
      return {};
    default:
      return state
  }
}

export default user;
