import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './comp-css/main.css';
// import { renderRoutes } from 'react-router-config';
import Loadable from 'react-loadable';
import './App.scss';
import {Redirect} from 'react-router';
import Settings from './lib/settings';
import Logout from './views/Pages/Logout';
import {connect} from 'react-redux'
import * as Actions from './actions';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = Loadable({
  loader: () => import('./containers/DefaultLayout'),
  loading
});

// Pages
const Login = Loadable({
  loader: () => import('./views/Pages/Login'),
  loading
});

const Register = Loadable({
  loader: () => import('./views/Pages/Register'),
  loading
});

const Page404 = Loadable({
  loader: () => import('./views/Pages/Page404'),
  loading
});

const Page500 = Loadable({
  loader: () => import('./views/Pages/Page500'),
  loading
});

const PrivateRoute = ({
  component: Component,
  auth: Aauth,
  ...rest
}) => (<Route {...rest} render={(props) => (
    Aauth === true
    ? <Component {...props}/>
    : <Redirect to='/login'/>)}/>)

class App extends Component {

  componentDidMount() {

    fetch("/api/islogged", Settings.fetchParams('POST')).then(res => {
      if (res.ok) {
        res.json().then(json => {

          if (!json.error) {
            if (json.logged) {
              this.props.dispatch(Actions.loginAction(
                json.user));
              this.setState({userItems: []});
            } else {
              this.props.dispatch(Actions.logoutAction());
            }
          }
        })
      }
    });
  }

  render() {
    return (
      <HashRouter>
          <Switch>
            <Route exact path="/login" name="Login Page" component={Login} />
            <Route exact path="/logout" name="Logout Page" component={Logout} />
            <Route exact path="/register" name="Register Page" component={Register} />
            <Route exact path="/404" name="Page 404" component={Page404} />
            <Route exact path="/500" name="Page 500" component={Page500} />
            <PrivateRoute path="/" name="Home" auth={this.props.admin} component={DefaultLayout} />
          </Switch>
      </HashRouter>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user,
    admin: state.admin,
  };
}
export default connect(mapStateToProps)(App);
