import React, {Component} from 'react';
import {Redirect} from 'react-router';
import Settings from '../../../lib/settings';
import * as Actions from '../../../actions';
import {connect} from 'react-redux';

class Logout extends Component {



  componentDidMount() {

    fetch("/api/logout", Settings.fetchParams('POST')).then(res => {
      if (res.ok) {
        res.json().then(json => {
          if (!json.error) {
            if(json.ok) {
              this.props.dispatch(Actions.logoutAction());
              alert("Byl jste odhlášen.");
            }
          } else {
            alert("Chyba při Odhlašování");
          }
        })
      } else {
        alert("Error");
      }
    });
  }

  render() {
    return <Redirect to="/"/>;
  }

}

function mapStateToProps(state){
  return {
    logged: state.auth,
  };
}


export default connect(mapStateToProps)(Logout);
